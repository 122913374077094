@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #f7f2f2;
  --text-primary: #000;
  --text-secondary: #8e05c2;
  --accent: #8e05c2;
  --border: #333;
  --form-bg: #fff;
  --toggle-bg: #8e05c2;
  --toggle-fg: #FFFFFF;
}

[data-theme='dark'] {
  --background: #000;
  --text-primary: #fff;
  --text-secondary: #8e05c2;
  --accent: #7d05c2;
  --border: #808080;
  --form-bg: #191919;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: var(--background);
  color: var(--text-primary);
}

.swiper-button-next:after, 
.swiper-button-prev:after{
    color: #a8dadc;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill{
    background: #a8dadc !important
}

html::-webkit-scrollbar{
    display: none;
}

html{
    scrollbar-width: none;
}


.page-container {
  min-height: 100vh;
}

a {
  text-decoration: none;
  font-weight: 600;
}


.top {
	font-style: oblique;
  text-align: center;
}
  
  .hide {
    display: none;
    position: absolute;
    bottom: 20px;
    left: 0;
    background-color: var(--background);
    padding: 2px;
    font-size: 14px;
    border-radius: 55;
  }
  
  .myDIV:hover + .hide {
    display: block;
  }


.desktop-menu ul li {
  cursor: pointer;
  font-size: 0.92rem;
  font-weight: 700;
  color: #151b19;
}
  

.theme-toggle i {
  font-size: 2rem;
  cursor: pointer;
}

.theme-toggle {
  text-align: center;
}

.theme {
  background-color: var(--background);
  color: var(--text-primary);
  font-size: .95rem;
}

.intro {
  background-color:  var(--background);
  z-index: 2; /* Un z-index mai mare decât cel al elementului .banner */
  margin-top: -100px; /* Sau cât este necesar */
  position: relative; /* Asigură-te că este setată pe relative sau absolute */
}

.StyledCard1 {
  border: 1px solid var(--accent);
  box-shadow: 0px 0px 10px rgba(68, 68, 68, 0.9);
  border-radius: 7px;
  margin-bottom: 10px;
  padding: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 1.1rem;
}

.StyledPoza {
  margin-bottom: 10px;
  padding: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 1.1rem;
}

.StyledPoza .FaRegUserCircle {
  width: 70px;
  height: auto; 
  opacity: 0.1;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin: auto;
  box-shadow: 0px 0px 12px var(--border);
  border-radius: 8px;
  background-color: var(--form-bg);
}


.divider {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #806b75;
  line-height: 1rem;
  margin: 1rem 0;
}

.divider1 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgba(132, 68, 158, 0.4);
  line-height: 1rem;
  margin: .1rem 0;
}

label {
  margin: 8px 0;
  font-weight: 600;
}

.input {
  padding: 10px;
  margin-bottom: 8px;
  border-radius: 8px;
  border: 1px solid var(--border);
  font-size: 0.95rem;
  background: var(--background);
  color: var(--text-primary);
}

.input:disabled {
  color: #d6d6d6;
}

button {
  background-color: var(--accent);
  color: var(--text-primary);
  border: 1px solid var(--accent);
  padding: 10px 100px;
  margin: 20px 0;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  font-size: 1rem;
  display: inline-block;

}

button:hover {
  color: white; 
}

.profile{
  border: 1px solid #3a0852;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
}

.profile .nr {
  box-shadow: 0px 0px 12px var(--accent);
  padding: 2px 10px;
  border-radius: 10px;
  display: inline-block;
}

.inscrieri-temp {
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 12px var(--accent);
  padding: 20px;
  margin: 10px auto; 
  border-radius: 10px;
  width: 300px; 
  position: fixed;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%);
  text-align: center; 
  z-index: 999; 
}
.anulat {
  border-radius: 10px;
  width: 98%; 
  position: fixed;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%);
  z-index: 999; 
}


.fixed-bottom-background {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 10;
} 


.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 10; 
}

.popup-content {
  position: relative;
}

.close-button {
  position: absolute;
  top: -15px; 
  right: 15px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  z-index: 10; 
}

.controls-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 30px; 
}


.play-pause-button {
  cursor: pointer;
  border: none;
  background: none;
  margin: 0 10px;
}

.progress-bar {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  border: 1px solid #3a0852;
  overflow: hidden;
  margin-top: 3px;
}

.progress-bar-fill {
  height: 100%;
  background-color: #611c81;
}
