.table {
  width: 100%;
}

.table th{
  font-weight: 500;
  text-align: center;
  width: 100px;
  height: 40px;
  padding-left: 1px; 
}
.table .th0{
  font-weight: 500;
  text-align: center;
  width: 70px;
  padding-left: 1px; 
}
.table .th1{
  font-weight: 500;
  text-align: center;
  width: 65px;
  padding-left: 1px; 
}
.table .th2{
  font-weight: 500;
  text-align: center;
  width: 50px;
  padding-left: 1px; 
}
.table td{
  text-align: center;
  width: 100px;
  height: 50px;
  padding-left: 1px; 
}
.table .td0 {
  text-align: center;
  width: 70px;
  padding-left: 1px; 
}
.table .td1 {
  text-align: center;
  width: 65px;
  padding-left: 1px; 
}
.table .td2 {
  text-align: center;
  width: 50px;
  padding-left: 1px; 
}

