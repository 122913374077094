.toggle-container {
  display: flex;
  margin-top: .32em;
}

.toggle {
  visibility: hidden;
}

.toggle + label {
  display: flex;
  align-items: center;
  font-size: 1em;
  cursor: pointer;
  color: var(--primary-text-color);
}

.toggle + label::before {
  content: "";
  height: 1em;
  width: 1.7em;
  border-radius: 1em;
  background-color: var(--toggle-bg);
  transition: background-color 250ms ease-in-out;
}

.toggle + label::after {
  content: "";
  height: 0.8em;
  width: 0.8em;
  border-radius: 1em;
  background-color: var(--toggle-fg);
  position: absolute;
  transition: background-color 250ms ease-in-out, transform 250ms ease-in-out;
}

.toggle:checked + label::after {
  transform: translateX(100%);
}

/* Stiluri pentru dispozitive mobile */
@media (max-width: 768px) {
  .toggle-container {
    position: fixed;
    top: 5.8em; /* Ajustează distanța de sus așa cum dorești */
    right: 1em; 
  }
}
