.active{
    color: #550375;
}

nav {
    
  background-color: #06200f;
  background-image: linear-gradient(45deg, #1e022b 1%, #bdacc4 );
  box-shadow: 0px 0px 50px rgba(43, 42, 42, 0.9);
  opacity: 0.8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 999;
}

nav .title {
    font-size: 1.1rem;
    margin: .2rem;
    font-weight: bold;
	font-style: oblique;
    text-decoration: none;
    color: white;
	margin-left: 2rem;
}

nav ul {
    display: flex;
}

nav ul li {
   list-style: none; 
}

nav ul li a {
    display: block;
    text-decoration: none;
	color: var(--text-primary);
	font-style: oblique;
    font-weight: 800;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;
}

nav ul li a:not(.active):hover {
    background-color:rgba(142, 5, 194, 0.3);
}

nav .menu {
    display: none;
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}

nav .menu span {
    height: 0.4rem;
    width: 100%;
    background-color: #fff;
}

.menu {
	display: flex;
	align-items: center;
	cursor: pointer;
  }
  
  .hamburger {
	font-size: 2rem; 
	color:  var(--accent);
	padding-top: 5px;
    padding-right: 7px;
  }
  
  
  .menu.active .hamburger {
	color: #39034e; 
  }

@media (min-width: 580px) {
	ul{
	  margin-right: 4.5rem;
	}
  
	nav .title {
	  margin-left: 3rem;
	}
  }


@media (max-width: 580px) {
    nav .menu {
        display: flex;
    }

    nav {
        flex-direction: column;
        align-items: flex-start;
    }

    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
		background-color:rgba(255, 255, 255, 0.1);
    }

    nav ul.open {
        display: flex;
    }

    nav ul li {
        width: 100%;
        text-align: center;
    }

    nav ul li a {
        margin: 0.2rem 0.5rem;
    }
}
